import Text from '../../../../../../components/Text';
import Title from '../../../../../../components/Title';
import style from './TextCard.module.scss';

const TextCard = ({ num, title, text, className }) => {
  return (
    <div className={`${style.card} ${className}`}>
      <Title h2 className={style.card__num}>
        {num}
      </Title>
      <Title h2 black className={style.card__title}>
        {title}
      </Title>
      <Text black className={style.card__text}>
        {text}
      </Text>
    </div>
  );
};

export default TextCard;
