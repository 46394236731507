import Button from '../../../../components/Button';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import { toAnchor } from '../../../../helpers/toAnchor';
import style from './Main.module.scss';

const MainSection = () => {
  return (
    <section className={style.main}>
      <div className='__container'>
        <div className={style.mainBody}>
          <Text md center fz16>
            NotEqual Web Agency - NEWA
          </Text>
          <Title h1 center className={style.mainBody__title}>
            Создаём сайты, <br />
            которые <span>продают</span>
          </Title>
          <Title h4 md center className={style.mainBody__subtitle}>
            Максимальная конверсия, минимальные сроки
          </Title>
          <Button
            title={'Получить бесплатную консультацию'}
            className={style.mainBody__btn}
            lg
            onClick={() => toAnchor('form')}
          />
        </div>
      </div>
    </section>
  );
};

export default MainSection;
