import style from './Card.module.scss';
import Title from '../../../../../../components/Title';
import Text from '../../../../../../components/Text';
import { useRef } from 'react';

import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Button from '../../../../../../components/Button';
import { toAnchor } from '../../../../../../helpers/toAnchor';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

const CasesCard = ({ link, img, title, subtitle, text, tags, reverse }) => {
  const containerRef = useRef(null);
  const hoverRef = useRef(null);

  useGSAP(
    () => {
      let proxy = { skew: 0 },
        skewSetter = gsap.quickSetter('.skewElem', 'skewY', 'deg'), // fast
        clamp = gsap.utils.clamp(-2, 2); // don't let the skew go beyond 20 degrees.

      ScrollTrigger.create({
        onUpdate: (self) => {
          let skew = clamp(self.getVelocity() / -300);
          // only do something if the skew is MORE severe. Remember, we're always tweening back to 0, so if the user slows their scrolling quickly, it's more natural to just let the tween handle that smoothly rather than jumping to the smaller skew.
          if (Math.abs(skew) > Math.abs(proxy.skew)) {
            proxy.skew = skew;
            gsap.to(proxy, {
              skew: 0,
              duration: 0.8,
              ease: 'power3',
              overwrite: true,
              onUpdate: () => skewSetter(proxy.skew),
            });
          }
        },
      });
    },
    {
      scope: containerRef,
    }
  );

  // useGSAP(
  //   () => {
  //     let mouseX = null;
  //     let mouseY = null;

  //     window.addEventListener('mousemove', (e) => {
  //       mouseX = e.clientX;
  //       mouseY = e.clientY;
  //     });

  //     let posX = null;
  //     let posY = null;

  //     gsap.to(
  //       {},
  //       {
  //         duration: 0.01,
  //         repeat: -1,
  //         onRepeat: () => {
  //           posX += (mouseX - posX) / 5;
  //           posY += (mouseY - posY) / 5;

  //           gsap.set(hoverRef.current, {
  //             css: {
  //               left: mouseX - hoverRef.current.offsetWidth / 2,
  //               top: mouseY - hoverRef.current.offsetHeight / 2,
  //             },
  //           });
  //         },
  //       }
  //     );
  //   },
  //   {
  //     scope: containerRef,
  //   }
  // );

  return (
    <div
      // href={link}
      // target='_blank'
      // rel='noreferrer'
      ref={containerRef}
      className={`${style.card} ${reverse && style.reverse}`}
    >
      <div className='__container'>
        <div className={style.cardBody}>
          {/* <div ref={hoverRef} className={style.card__hover}>
            <svg
              width='36'
              height='36'
              viewBox='0 0 36 36'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g>
                <path
                  d='M24.75 0C23.843 0 23.0203 0.548437 22.6687 1.39219C22.3172 2.23594 22.5141 3.19922 23.1539 3.84609L26.0648 6.75L14.1609 18.6609C13.282 19.5398 13.282 20.9672 14.1609 21.8461C15.0398 22.725 16.4672 22.725 17.3461 21.8461L29.25 9.93516L32.1609 12.8461C32.8078 13.493 33.7711 13.6828 34.6148 13.3313C35.4586 12.9797 36.007 12.1641 36.007 11.25V2.25C36.007 1.00547 35.0016 0 33.757 0L24.75 0ZM5.625 2.25C2.51719 2.25 0 4.76719 0 7.875V30.375C0 33.4828 2.51719 36 5.625 36H28.125C31.2328 36 33.75 33.4828 33.75 30.375V22.5C33.75 21.2555 32.7445 20.25 31.5 20.25C30.2555 20.25 29.25 21.2555 29.25 22.5V30.375C29.25 30.9937 28.7437 31.5 28.125 31.5H5.625C5.00625 31.5 4.5 30.9937 4.5 30.375V7.875C4.5 7.25625 5.00625 6.75 5.625 6.75H13.5C14.7445 6.75 15.75 5.74453 15.75 4.5C15.75 3.25547 14.7445 2.25 13.5 2.25H5.625Z'
                  fill='white'
                />
              </g>
            </svg>
          </div> */}
          <div className={`${style.cardBody__img} skewElem`}>
            <img src={img} alt='' />
          </div>
          <div className={style.cardBody__content}>
            <Title h2>{title}</Title>
            <Title h4 semiBold className={style.cardBody__contentSubtitle}>
              {subtitle}
            </Title>
            <Text className={style.cardBody__contentText}>{text}</Text>
            <div className={style.cardBody__contentTags}>
              <Title h4 semiBold className={style.title}>
                Теги:
              </Title>
              {tags.map((item, index) => (
                <span key={index}>{item}</span>
              ))}
            </div>
            <Button
              title={'Обсудить проект'}
              onClick={() => toAnchor('form')}
              className={style.cardBody__contentBtn}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CasesCard;
