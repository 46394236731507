import style from './Cases.module.scss';
import CasesCard from './components/Card';
import cherchillImg from './assets/cherchill.jpg';
import crepezhImg from './assets/crepezh.jpg';
// import tainetImg from './assets/tainet.jpg';
// import img1 from './assets/img1.jpg';

const CasesSection = () => {
  return (
    <section id='cases' className={style.cases}>
      <div className='__container'>
        <div className={style.casesBody}>
          <CasesCard
            link='https://xn--h1adghbanaijbt.xn--p1ai/'
            img={crepezhImg}
            title={'Казанский Крепеж'}
            subtitle={'Лендинг для магазина строительных материалов'}
            text={`
              Мы разработали современный лендинг с удобной навигацией и адаптивным дизайном для магазина Казанский Крепеж. Благодаря улучшенному пользовательскому опыту, конверсии выросли на 35% уже в первый месяц.
              `}
            tags={['Лендинг', 'Анимации', 'Премиум дизайн']}
            reverse
          />
          <CasesCard
            link='https://demo.newa.agency/cherchill/'
            img={cherchillImg}
            title={'Черчилль'}
            subtitle={'Лендинг для школы английского языка'}
            text={
              'Для онлайн-школы Черчилль мы создали лендинг с высокой конверсией, который привлёк более 4 000 заявок. Интуитивный дизайн и акценты на призывах к действию значительно увеличили количество новых учеников.'
            }
            tags={['Лендинг', 'Анимации', 'Премиум дизайн']}
          />
        </div>
      </div>
    </section>
  );
};

export default CasesSection;
