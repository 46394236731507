import style from './Radio.module.scss';

const Radio = ({
  name,
  title,
  value,
  isError,
  className,
  register,
  required = false,
  ...rest
}) => {
  return (
    <label className={`${style.radio} ${isError && style.err} ${className}`}>
      <input
        {...register(name, { required: required })}
        {...rest}
        type='radio'
        name={name}
        value={value}
      />
      <div className={style.radioFake}>{title}</div>
    </label>
  );
};

export default Radio;
