import { Outlet, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Fluid from './lib/fluid';
import './scss/style.scss';
import Footer from './components/Footer';

function App() {
  const location = useLocation();

  return (
    <div className='wrapper'>
      {location.pathname !== '/policy' && <Fluid />}

      <Header />

      {/* <div style={{ padding: `${pagePadding}px 0 0` }} className='page'> */}
      <div className='page'>
        <Outlet />
      </div>

      <Footer />
    </div>
  );
}

export default App;
