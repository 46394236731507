import { useGSAP } from '@gsap/react';
import Text from '../../../../components/Text';
import Title from '../../../../components/Title';
import style from './Advantages.module.scss';
import img from './assets/img.jpg';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useRef } from 'react';
import { imageSequence } from '../../../../helpers/gsapSequence';
import projectsVideo from './assets/projects-preview.mp4';
import uxVideo from './assets/ux-preview.mp4';
import { useMediaQuery } from 'usehooks-ts';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

const AdvantagesSection = () => {
  const sectionRef = useRef(null);
  const canvasRef = useRef(null);
  const titleRef = useRef(null);
  const subtitleRef = useRef(null);
  const textRef = useRef(null);
  const previewRef = useRef(null);
  const isTablet = useMediaQuery('(max-width:991px)');

  useGSAP(
    () => {
      const animEnd = `${sectionRef.current.scrollHeight * 3}px`;
      const previewCardList = sectionRef.current.querySelectorAll(
        `.${style.advantagesBody__preview} div`
      );
      const subtitleList = sectionRef.current.querySelectorAll(
        `.${style.advantagesBody__subtitleItem}`
      );
      const textList = sectionRef.current.querySelectorAll(
        `.${style.advantagesBody__textItem}`
      );

      let frameCount = 99,
        urls = new Array(frameCount)
          .fill()
          .map(
            (o, i) =>
              `./assets/advantages-sequence/${(i + 1)
                .toString()
                .padStart(3, '0')}.jpg`
          );

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: sectionRef.current,
          start: 'top',
          end: animEnd,
          scrub: true,
          pin: true,
          markers: false,
        },
      });

      imageSequence({
        urls,
        canvas: canvasRef.current,
        scrollTrigger: {
          scrub: true,
          trigger: sectionRef.current,
          pin: false,
          markers: false,
          start: 'top',
          end: animEnd,
        },
      });

      tl.from(
        canvasRef.current,
        {
          opacity: 0,
          duration: 0.33,
        },
        0
      );
      tl.to(
        canvasRef.current,
        {
          opacity: 0,
          duration: 0.15,
        },
        0.85
      );
      tl.from(
        previewRef.current,
        {
          y: window.innerHeight * 0.75,
          scale: 2,
          duration: 0.33,
        },
        0
      );
      tl.from(
        previewCardList[0].querySelector('video'),
        {
          scale: 1.5,
          duration: 0.33,
        },
        0
      );
      tl.from(
        titleRef.current,
        {
          opacity: 0,
          duration: 0.33,
        },
        0
      );
      tl.from(
        subtitleRef.current,
        {
          opacity: 0,
          y: -50,
          duration: 0.33,
        },
        0
      );
      tl.from(
        textRef.current,
        {
          opacity: 0,
          y: -100,
          duration: 0.33,
        },
        0
      );

      previewCardList.forEach((item, index) => {
        if (index === 0) {
          tl.to(
            item,
            {
              opacity: 0,
              x: -1000,
              y: 250,
              scale: 0.5,
              duration: 0.33,
            },
            0.33
          );
        } else if (index === 1) {
          tl.from(
            item,
            {
              opacity: 0,
              x: 1000,
              y: 250,
              duration: 0.33,
              scale: 0.5,
            },
            0.33
          );
          tl.to(
            item,
            {
              // opacity: 0,
              // x: -500,
              // y: 0,
              y: isTablet ? -75 : -200,
              scale: 0.5,
              duration: 0.33,
            },
            0.66
          );
          // tl.to(
          //   item,
          //   {
          //     opacity: 0,
          //     x: -700,
          //     y: 200,
          //   },
          //   0.5 + index
          // );
        }
      });
      subtitleList.forEach((item, index) => {
        if (index === 0) {
          tl.to(
            item,
            {
              opacity: 0,
              scale: 0.5,
              duration: 0.33,
            },
            0.33
          );
        } else if (index === 1) {
          tl.from(
            item,
            {
              opacity: 0,
              duration: 0.33,
              scale: 0.5,
            },
            0.33
          );
          tl.to(
            item,
            {
              // opacity: 0,
              // scale: 0.5,
              duration: 0.33,
            },
            0.66
          );
        }
      });
      textList.forEach((item, index) => {
        if (index === 0) {
          tl.to(
            item,
            {
              opacity: 0,
              scale: 0.5,
              duration: 0.33,
            },
            0.33
          );
        } else if (index === 1) {
          tl.from(
            item,
            {
              opacity: 0,
              duration: 0.33,
              scale: 0.5,
            },
            0.33
          );
          tl.to(
            item,
            {
              // opacity: 0,
              // scale: 0.5,
              duration: 0.33,
            },
            0.66
          );
        }
      });
    },
    {
      scope: sectionRef,
    }
  );

  return (
    <section ref={sectionRef} className={style.advantages}>
      <div className={style.advantages__bg}>
        <canvas width={1920} height={1080} ref={canvasRef}></canvas>
      </div>

      <div className='__container'>
        <div className={style.advantagesBody}>
          <Title
            ref={titleRef}
            h1
            center
            className={style.advantagesBody__title}
          >
            Создаём сайты, которые усиливают ваш бренд
          </Title>
          <div ref={subtitleRef} className={style.advantagesBody__subtitle}>
            <Title h2 center className={style.advantagesBody__subtitleItem}>
              <span>Премиум дизайн, который продаёт</span>
            </Title>
            <Title h2 center className={style.advantagesBody__subtitleItem}>
              <span>Анимации и UX, которые удерживают клиента</span>
            </Title>
          </div>
          <div ref={textRef} className={style.advantagesBody__text}>
            <Text center className={style.advantagesBody__textItem}>
              Мы создаём уникальные дизайны, которые не только привлекают
              внимание, но и повышают доверие к вашему бренду. Ваш сайт будет
              выделяться среди конкурентов и усиливать вашу позицию на рынке,
              что приведёт к росту продаж.
            </Text>
            <Text center className={style.advantagesBody__textItem}>
              Мы добавляем анимации и интерактивные элементы, которые создают
              wow-эффект и делают ваш бренд запоминающимся. Эти решения улучшают
              восприятие сайта, заставляя пользователей оставаться дольше и чаще
              совершать целевые действия.
            </Text>
          </div>
          <div ref={previewRef} className={style.advantagesBody__preview}>
            <div className={style.advantagesBody__previewItem}>
              <video
                preload='none'
                src={projectsVideo}
                playsInline
                muted
                autoPlay
                loop
              ></video>
            </div>
            <div className={style.advantagesBody__previewItem}>
              <video
                preload='none'
                src={uxVideo}
                playsInline
                muted
                autoPlay
                loop
              ></video>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdvantagesSection;
