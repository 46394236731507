import style from './Cta.module.scss';
import Title from '../../../../components/Title';
import Text from '../../../../components/Text';
import Button from '../../../../components/Button';
import { useMediaQuery } from 'usehooks-ts';
import { toAnchor } from '../../../../helpers/toAnchor';

const CtaSection = () => {
  const isTablet = useMediaQuery('(max-width:991px)');

  return (
    <section className={style.cta}>
      <div className='__container'>
        <div className={style.ctaBody}>
          <div className={style.ctaBody__title}>
            {isTablet ? (
              <>
                <Title h1>Давайте начнем работу над вашим проектом</Title>
              </>
            ) : (
              <>
                <Title h1 className={style.ctaBody__titleRow}>
                  Давайте начнем работу
                </Title>
                <Title h1 className={style.ctaBody__titleRow}>
                  над вашим проектом
                </Title>
              </>
            )}
          </div>
          <div className={style.ctaBody__bottom}>
            <Text className={style.ctaBody__bottomText}>
              Мы создадим уникальную стратегию, разработаем структуру сайта и
              предложим креативные решения, чтобы ваш проект выделялся на фоне
              конкурентов.
            </Text>
            <Button
              title='Начать обсуждение'
              black
              lg
              className={style.ctaBody__bottomBtn}
              onClick={() => toAnchor('form')}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CtaSection;
