import style from './Card.module.scss';
import Title from '../../../../../../components/Title';
import Text from '../../../../../../components/Text';
import Button from '../../../../../../components/Button';
import { toAnchor } from '../../../../../../helpers/toAnchor.js';

const ServicesCard = ({ title, list, text, btnText, className }) => {
  return (
    <div className={`${style.card} ${className}`}>
      <div className={style.cardBack}></div>
      <div className={style.cardFront}>
        <Title h4 center className={style.card__title}>
          {title}
        </Title>
        <Text center className={style.card__text}>
          {text}
        </Text>
        {list && (
          <ul className={style.card__list}>
            {list.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        )}
        <Button
          onClick={() => toAnchor('form')}
          black
          className={style.card__btn}
          // title={'Свяжитесь с нами'}
          title={btnText}
        />
      </div>
    </div>
  );
};

export default ServicesCard;
