import style from './Text.module.scss';

const Text = ({ children, fz16, fz14, md, black, center, className }) => {
  return (
    <div
      className={`${style.text}
      ${fz16 && style.fz16}
      ${fz14 && style.fz14}
      ${md && style.md}
      ${center && style.center}
      ${black && style.black}
      ${className}
  `}
    >
      {children}
    </div>
  );
};

export default Text;
