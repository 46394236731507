import Title from '../../../../components/Title';
import FaqAccordion from './components/Accordion';
import style from './Faq.module.scss';

const FaqSection = () => {
  return (
    <section id='faq' className={style.faq}>
      <div className='__container'>
        <Title h1>Часто задаваемые вопросы</Title>
        <div className={style.faqBody}>
          <FaqAccordion
            title={'Какие сроки реализации проекта ?'}
            text={
              'Сроки зависят от сложности проекта и начинаются от 7 рабочих дней. Если вам необходимо срочное исполнение, мы можем ускорить процесс с небольшим увеличением стоимости.'
            }
          />
          <FaqAccordion
            title={'Какие гарантии на работу ?'}
            text={
              <>
                Мы предлагаем 3 ключевые гарантии: <br />-{' '}
                <b>Гарантия качества:</b> Исправим любые недочеты, возникшие по
                нашей вине, в течение года бесплатно. <br />-{' '}
                <b>Гарантия сроков:</b> Если сроки будут нарушены, мы возьмем на
                себя все дополнительные расходы. <br />-{' '}
                <b>Гарантия фиксированной стоимости:</b> Все расходы фиксируются
                в договоре, без скрытых платежей!
              </>
            }
          />
          <FaqAccordion
            title={'Как формируется цена на создание сайта ?'}
            text={
              'Стоимость разработки определяется на основе времени, затраченного нашими специалистами. Мы предоставляем прозрачный расчет: ставка за час работы умножается на количество затраченных часов.'
            }
          />
          <FaqAccordion
            title={'Почему ваши сайты приносят результат ?'}
            text={
              'Мы проводим детальный анализ целевой аудитории и конкурентных преимуществ перед началом работ. Это позволяет нам создать эффективную структуру сайта, которая максимизирует конверсию. Мы также продолжаем анализировать поведение пользователей после запуска, чтобы вносить необходимые улучшения.'
            }
          />
          <FaqAccordion
            title={'Почему ваши услуги стоят столько ?'}
            text={
              'Мы специализируемся на создании высокоэффективных сайтов, которые помогут вам увеличить выручку. Профессиональный подход и качественное исполнение требуют соответствующей инвестиции. Выбирайте между получением максимального результата и экономией на будущем!'
            }
          />
          <FaqAccordion
            title={'Смогу ли я вносить изменения на сайте?'}
            text={
              'Конечно! Если вы выбрали сайт на движке (CMS), мы обучим вас работать с админ-панелью и предоставим видеоинструкцию для вашего удобства.'
            }
          />
          <FaqAccordion
            title={'Сколько правок можно внести при разработке дизайна сайта?'}
            text={
              'После утверждения прототипа вы получите 5 бесплатных правок. Если вам потребуется внести дополнительные изменения, они будут оплачиваться отдельно по тарифу час работы специалиста.'
            }
          />
          <FaqAccordion
            title={'Как заказать сайт под ключ?'}
            text={
              'Вы можете легко заказать сайт, оставив заявку на нашем сайте или написав в телеграм @newa_manager. Мы обсудим детали вашего проекта и подпишем договор. После этого вы внесете предоплату в размере 50%.'
            }
          />
          <FaqAccordion
            title={'Мне сделали сайт, что делать дальше?'}
            text={
              'Чтобы привлечь посетителей на ваш сайт, рекомендуем использовать SEO-продвижение, контекстную рекламу в Яндекс Директ и Google Ads, а также таргетированную рекламу в социальных сетях.'
            }
          />
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
