import style from './Button.module.scss';

const Button = ({ onClick, className, title, black, lg }) => {
  return (
    <button
      onClick={onClick}
      data-title={title}
      className={`${style.button} 
      ${lg && style.lg}
      ${black && style.black}
       ${className}`}
    >
      {title}
    </button>
  );
};

export default Button;
