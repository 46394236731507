import { useGSAP } from '@gsap/react';
import Title from '../../../../components/Title';
import TextCard from './components/TextCard';
import style from './Steps.module.scss';
import { useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { imageSequence } from '../../../../helpers/gsapSequence';
import { useMediaQuery } from 'usehooks-ts';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

const StepsSection = () => {
  const sectionRef = useRef(null);
  const bodyRef = useRef(null);
  const canvasRef = useRef(null);
  const isTablet = useMediaQuery('(max-width:991px)');

  useGSAP(
    () => {
      const animEnd = `${sectionRef.current.scrollHeight * 3}px`;
      const itemList = sectionRef.current.querySelectorAll(
        `.${style.stepsBody__contentCard}`
      );

      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: sectionRef.current,
          pin: false,
          markers: false,
          anticipatePin: 1, // добавьте эту строку

          scrub: 2,
          start: 'top top',
          end: animEnd,

          ease: 'none',
        },
      });
      const tlBody = gsap.timeline({
        scrollTrigger: {
          trigger: sectionRef.current,
          pin: false,
          markers: false,
          anticipatePin: 1, // добавьте эту строку

          scrub: 2,
          start: '-=500',
          end: 'top top',

          ease: 'none',
        },
      });

      let frameCount = 99,
        urls = new Array(frameCount)
          .fill()
          .map(
            (o, i) =>
              `./assets/steps-sequence/${(i + 1)
                .toString()
                .padStart(3, '0')}.jpg`
          );

      imageSequence({
        urls,
        canvas: canvasRef.current,
        scrollTrigger: {
          scrub: true,
          trigger: sectionRef.current,
          pin: true,
          markers: false,
          // start: () => `-=${window.innerHeight * 0.25}`,
          // end: () => `+=${window.innerHeight * 1}`,
          // start: () => `top`,
          // end: () => `bottom`,
          start: 'top top',
          end: animEnd,
          // ease: 'none',
        },
      });

      itemList.forEach((element, index) => {
        if (index === 0) {
          // tl.from(element, {
          //   y: 0,
          // });
          tl.to(
            element,
            {
              x: isTablet ? -400 : 0,
              y: !isTablet ? -400 : 0,
              opacity: 0,
              scale: 0.5,
              duration: 1 / (itemList.length * 2 - index),
            },
            0.02
          );
        } else if (index === itemList.length - 1) {
          tl.from(
            element,
            {
              // x: isTablet ? -400 : 0,
              y: 400,
              opacity: 0,
              scale: 0.5,
              duration: 1 / (itemList.length * 2 - index),
            },
            (1 / (itemList.length * 2 - index)) * (index - 1)
            // 0
            // 0.2
          );
        } else {
          tl.from(
            element,
            {
              // x: isTablet ? 400 : 0,
              y: 400,
              opacity: 0,
              scale: 0.5,
              duration: 1 / (itemList.length * 2 - index),
            },
            (1 / (itemList.length * 2 - index)) * (index - 1)
            // 0
            // 0.2
          );
          tl.to(
            element,
            {
              x: isTablet ? -400 : 0,
              y: !isTablet ? -400 : 0,
              opacity: 0,
              scale: 0.5,
              duration: 1 / (itemList.length * 2 - index) + 0.1,
            },
            (1 / (itemList.length * 2 - index)) * (index - 0)
            // 0.2 * 1 = 0.2
            // 0.2 * 2 = 0.4

            // (1 / (itemList.length * 2)) * index
            // 0.4
          );
        }
      });

      tlBody.from(
        bodyRef.current,
        {
          y: window.innerHeight / 2,
          scale: 0.5,
        },
        0
      );
    },
    {
      scope: sectionRef,
    }
  );

  return (
    <section id='steps' ref={sectionRef} className={style.steps}>
      <div className='__container'>
        <Title h1 center>
          Наш процесс работы
        </Title>
        <div ref={bodyRef} className={style.stepsBody}>
          <div className={style.stepsBody__content}>
            <TextCard
              num={'01'}
              title={'Проводим интервью'}
              text={
                <>
                  Мы предлагаем несколько способов взаимодействия: вы можете
                  выбрать удобный вариант — заполнить бриф или провести
                  60-минутный созвон. Это поможет нам лучше понять ваши цели и
                  ожидания.
                </>
              }
              className={style.stepsBody__contentCard}
            />
            <TextCard
              num={'02'}
              title={'Исследование и генерация идей'}
              text={
                <>
                  Погружаемся в ваш бизнес, анализируем целевую аудиторию и
                  находим ключевые точки соприкосновения с вашим продуктом, что
                  позволяет создать уникальное и эффективное решение.
                </>
              }
              className={style.stepsBody__contentCard}
            />
            <TextCard
              num={'03'}
              title={'Создание текстов и прототипа'}
              text={
                <>
                  Прорабатываем структуру сайта и удобство пользования. Создаём
                  прототип, который демонстрирует расположение текстов и
                  элементов, чтобы вы могли визуализировать, как будет выглядеть
                  конечный результат.
                </>
              }
              className={style.stepsBody__contentCard}
            />
            <TextCard
              num={'04'}
              title={'Разработка дизайна и анимации'}
              text={
                <>
                  Собираем референсы и создаём визуальный стиль, включая
                  дизайн-концепцию и креативные решения. Добавляем анимации,
                  которые сделают ваш сайт привлекательным и запоминающимся.
                </>
              }
              className={style.stepsBody__contentCard}
            />
            <TextCard
              num={'05'}
              title={'Верстка и настройка сайта'}
              text={
                <>
                  Мы верстаем ваш сайт и интегрируем с CMS (опционально),
                  настраиваем все необходимые функции — домен, формы обратной
                  связи, делаем базовое SEO и подключаем метрики.
                </>
              }
              className={style.stepsBody__contentCard}
            />
            <TextCard
              num={'06'}
              title={'Запуск и продвижение'}
              text={
                <>
                  Публикуем сайт и делаем его доступным для пользователей.
                  Собираем данные, тестируем и дорабатываем, чтобы гарантировать
                  высокую производительность и удовлетворение потребностей
                  клиентов.
                </>
              }
              className={style.stepsBody__contentCard}
            />
          </div>
          <div className={style.stepsBody__anim}>
            <canvas width={1920} height={1080} ref={canvasRef}></canvas>
          </div>
        </div>
      </div>
    </section>
  );
};

export default StepsSection;
