import style from './Services.module.scss';
import Title from '../../../../components/Title';
import ServicesCard from './components/Card';
import { useGSAP } from '@gsap/react';
import { useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useMediaQuery } from 'usehooks-ts';

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

const ServicesSection = () => {
  const sectionRef = useRef(null);
  const bodyRef = useRef(null);
  const isTablet = useMediaQuery('(max-width:991px)');

  useGSAP(
    () => {
      if (!isTablet) {
        const itemList = sectionRef.current.querySelectorAll(
          `.${style.servicesBody__card}`
        );
        // const gapSize = window.innerWidth > 1440 ? 28 : 20;
        const gapSize = 60;

        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: sectionRef.current,
            pin: true,
            markers: false,
            anticipatePin: 1, // добавьте эту строку

            scrub: 2,
            start: 'top top',
            end: `${sectionRef.current.scrollHeight * 2}px`,

            ease: 'none',
          },
        });

        itemList.forEach((item, index) => {
          tl.to(item, {}, 0);

          tl.to(
            item,
            {
              rotate: -11 * (index + 1),
              ease: 'none',
            },
            {
              ease: 'none',
            },
            0
          );
          tl.fromTo(
            item,
            {
              y: window.innerHeight * 0.4,
              x:
                bodyRef.current.offsetWidth / 2 -
                item.offsetWidth / 2 -
                item.offsetWidth * index -
                gapSize * index,
              ease: 'none',
              // rotate: -5 * (index + 1),
            },
            {
              y: 0,
              x: 0,
              rotate: 0,
              ease: 'none',
            },
            0.5
          );
          tl.to(
            item,
            {
              rotateY: 0,
              ease: 'none',
            },
            0.52 * (index / 4 + 1)
          );
        });
      }
    },
    {
      scope: sectionRef,
    }
  );

  return (
    <section id='services' ref={sectionRef} className={style.services}>
      <div className='__container'>
        <Title h1 center>
          Наши услуги
        </Title>
        <div ref={bodyRef} className={style.servicesBody}>
          <ServicesCard
            className={style.servicesBody__card}
            title={'Быстрый запуск лендинга'}
            text={`
              Бюджетное решение для создания лендинга с помощью конструктора. Быстрая разработка и запуск, идеально подходит для тестирования идей или временных рекламных кампаний.
              `}
            btnText={'Запустить лендинг за 7 дней'}
          />
          <ServicesCard
            className={style.servicesBody__card}
            title={'Премиум лендинг'}
            text={
              'Разработка уникального лендинга с премиум дизайном и ручной версткой. Опционально добавляется CMS для лёгкого управления контентом. Идеально для тех, кто хочет выделиться среди конкурентов.'
            }
            btnText={'Получить уникальный лендинг'}
          />
          <ServicesCard
            className={style.servicesBody__card}
            title={'Корпоративный сайт'}
            text={
              'Многостраничный сайт с премиум дизайном и CMS для удобного обновления контента. Подходит для компаний, которым нужен мощный онлайн-инструмент для взаимодействия с клиентами.'
            }
            btnText={'Создать корпоративный сайт'}
          />
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
