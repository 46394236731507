import style from './Title.module.scss';

const Title = ({
  children,
  h1,
  h2,
  h3,
  h4,
  h5,
  md,
  black,
  semiBold,
  center,
  className,
}) => {
  return (
    <div
      className={`${style.title} 
  ${h1 && style.h1}
  ${h2 && style.h2}
  ${h3 && style.h3}
  ${h4 && style.h4}
  ${h5 && style.h5}
  ${md && style.md}
  ${semiBold && style.semiBold}
  ${center && style.center}
  ${black && style.black}
  ${className}
  `}
    >
      {children}
    </div>
  );
};

export default Title;
