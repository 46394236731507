import { useRef, useState } from 'react';
import Text from '../../../../../../components/Text';
import Title from '../../../../../../components/Title';
import style from './Accordion.module.scss';

const FaqAccordion = ({ title, text, className }) => {
  const contentRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    if (isOpen) {
      setIsOpen(false);
      contentRef.current.style.height = 0;
    } else {
      setIsOpen(true);
      contentRef.current.style.height = contentRef.current.scrollHeight + 'px';
    }
  };

  return (
    <div className={`${style.accordion} ${isOpen && style.open}`}>
      <div onClick={toggleAccordion} className={style.accordionTop}>
        <Title h3 md className={style.accordionTop__title}>
          {title}
        </Title>
        <div className={style.accordionTop__arrow}>
          <svg
            width='36'
            height='19'
            viewBox='0 0 36 19'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect
              x='1.56409'
              y='0.740234'
              width='24'
              height='1'
              transform='rotate(45 1.56409 0.740234)'
              fill='#D9D9D9'
            />
            <rect
              x='35.6779'
              y='0.707031'
              width='24'
              height='1'
              transform='rotate(135 35.6779 0.707031)'
              fill='#D9D9D9'
            />
          </svg>
        </div>
      </div>
      <div ref={contentRef} className={style.accordionContent}>
        <Text>{text}</Text>
      </div>
    </div>
  );
};

export default FaqAccordion;
